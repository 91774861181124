<template>
  <div>
    <c-table
      ref="table"
      title="내부심사 체크리스트"
      :columns="grid.columns"
      :data="targetDept.checklist"
      :gridHeight="gridHeight"
      :merge="grid.merge"
      :editable="editable && !disabled && checkAllFlag"
      :isExcelDown="true"
      :filtering="false"
      :columnSetting="false"
      @table-data-change="tableDataChange"
    >
      <template slot="table-chip">
        <q-chip
          v-for="(dept, idx) in options"
          outline square
          :key="idx"
          :removable="false"
          :color="dept.selected ? (dept.code == 'ALL' ? 'orange' : 'primary') : 'grey-6'"
          text-color="white"
          icon="memory"
          :selected.sync="dept.selected"
          :label="$language(dept.codeName)"
          v-model="dept.check"
          @update:selected="state => selectedDept(state, dept, idx)"
        >
          <q-tooltip v-if="dept.code != 'ALL'">{{$language('심사자/부서')}}</q-tooltip>
          <q-tooltip v-else>{{$language('내부심사 체크리스트 결과')}}</q-tooltip>
        </q-chip>
      </template>
      <template slot="prefixContent">
        <font color="#C10015">
          {{statusByDept}}
        </font>
      </template>
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- <c-btn 
            v-if="editable&&!disabled" 
            label="LBLREMOVE" icon="remove" 
            @btnClicked="deleteChecklist" /> -->
          <!-- <c-btn 
            v-if="editable&&!disabled" 
            label="LBLADD" icon="add" 
            @btnClicked="addChecklist" /> -->
          <c-btn 
            v-if="editable && !disabled && checkAllFlag" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="targetDept.checklist"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveChecklist"
            @btnCallback="saveChecklistCallback" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable && !disabled && checkAllFlag"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="saiInternalActionChecklistResultId"
            ibmTaskTypeCd="ITT0000150"
            ibmTaskUnderTypeCd="ITTU000155"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'internal-action-checklist',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiInternalActionTargetDeptId: '',
        isAuthData: false,
      }),
    },
    targetDept: {
      type: Object,
      default: () => ({
        saiInternalActionTargetDeptId: '',  // 내부심사 피심사팀 일련번호
        saiInternalActionId: '',  // 내부심사 수행계획서 일련번호
        deptCd: '',  // 피심사부서코드
        saiInternalActionTargetDeptStepCd: '',  // 내부심사 피심사팀 진행상태
        resultRemark: '',  // 결과요약
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        checklist: [], // 체크리스트 결과
        auditTeams: [], // 심사팀
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'targetDeptName' },
          { index: 1, colName: 'saiInternalAuditClassName' },
          { index: 2, colName: 'saiInternalAuditClassNameGubun' },
        ],
        columns: [
          // {
          //   name: 'targetDeptName',
          //   field: 'targetDeptName',
          //   label: '피심사팀',
          //   align: 'center',
          //   style: 'width:120px',
          //   sortable: false,
          // },
          {
            name: 'saiInternalAuditClassName',
            field: 'saiInternalAuditClassName',
            label: 'ISO 구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'systemElementsName',
            field: 'systemElementsName',
            label: '관련표준',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          // {
          //   name: 'sortOrder',
          //   field: 'sortOrder',
          //   label: '순번',
          //   align: 'center',
          //   style: 'width:30px',
          //   sortable: false,
          // },
          {
            name: 'itemName',
            field: 'itemName',
            label: '심사 항목',
            align: 'left',
            style: 'width:450px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'aimMatter',
            field: 'aimMatter',
            label: '비고',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'result',
            field: 'result',
            label: '내부심사결과',
            child: [
              {
                name: 'sar0000001',
                field: 'sar0000001',
                label: '적합',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                checkIcon: 'task_alt',
                uncheckIcon: 'radio_button_unchecked',
                'true': 'Y',
                'false': 'N',
                color: 'green', 
              },
              {
                name: 'sar0000005',
                field: 'sar0000005',
                label: '부적합',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                checkIcon: 'task_alt',
                uncheckIcon: 'radio_button_unchecked',
                'true': 'Y',
                'false': 'N',
                color: 'green', 
              },
              // {
              //   name: 'sar0000010',
              //   field: 'sar0000010',
              //   label: '관찰사항',
              //   align: 'center',
              //   style: 'width:40px',
              //   sortable: false,
              //   type: 'check',
              //   checkIcon: 'task_alt',
              //   uncheckIcon: 'radio_button_unchecked',
              //   'true': 'Y',
              //   'false': 'N',
              //   color: 'green', 
              // },
              // {
              //   name: 'sar0000015',
              //   field: 'sar0000015',
              //   label: '비적용',
              //   align: 'center',
              //   style: 'width:40px',
              //   sortable: false,
              //   type: 'check',
              //   checkIcon: 'task_alt',
              //   uncheckIcon: 'radio_button_unchecked',
              //   'true': 'Y',
              //   'false': 'N',
              //   color: 'green', 
              // },
            ]
          },
          {
            name: 'observation',
            field: 'observation',
            label: '관찰내용',
            align: 'left',
            style: 'width:250px',
            sortable: false,
            type: 'textarea',
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선번호/개선진행상태',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
        height: '780px',
      },
      selectedDeptCd: '',
      selectedDeptName: '',
      check: false,
      editable: true,
      listUrl: '',
      listDeptUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      statusByDept: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let _margin = 130;
      if (!this.disabled) {
        _margin += 40
      }
      return String(this.contentHeight - _margin) + 'px';
    },
    targetDepts() {
      return this.targetDept.auditTeams
    },
    options() {
      let _options = [];
      _options.push({
        code: 'ALL',
        codeName: '심사팀장',
        selected: true,
      })
      // if (this.targetDepts !== undefined) {
      //   if (this.targetDepts && this.targetDepts.length > 0) {
      //     this.$_.forEach(this.targetDepts, item => {
      //       _options.push({
      //         code: item.teamDeptCd,
      //         codeName: item.userName,
      //         selected: false,
      //       })
      //     })
      //   }
      // }
      return _options;
    },
    checkAllFlag() {
      return this.selectedDeptCd == 'ALL';
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['systemElementsName'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.internal.action.checklistResult.list.url;
      this.saveUrl = transactionConfig.sai.internal.action.checklistResult.save.url;
      this.deleteUrl = transactionConfig.sai.internal.action.checklistResult.delete.url;
      this.listDeptUrl = selectConfig.sai.internal.action.checklistResult.depts.url;
      // code setting
      // list setting
      this.selectedDeptCd = 'ALL';
    },
    getChecklist() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        saiInternalActionTargetDeptId: this.targetDept.saiInternalActionTargetDeptId
      }
      this.$http.request((_result) => {
        this.$set(this.targetDept, 'checklist', _result.data)
        this.statusByDept = '';
      },);
    },
    getCheckDeptlist(_deptCd) {
      this.$http.url = this.listDeptUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        saiInternalActionTargetDeptId: this.targetDept.saiInternalActionTargetDeptId,
        saiInternalActionId: this.targetDept.saiInternalActionId,
        deptCd: this.targetDept.deptCd,
        teamDeptCd: _deptCd,
      }
      this.$http.request((_result) => {
        this.$set(this.targetDept, 'checklist', _result.data.list)
        this.statusByDept = _result.data.internalAuditResultStatusName;
      },);
    },
    tableDataChange(props, col) {
      // let blackList = ['itemName', 'aimMatter', 'observation']
      let whiteList = ['sar0000001', 'sar0000005', 'sar0000010', 'sar0000015']
      if (whiteList.indexOf(col.name) > -1 && props.row[col.name] === 'Y') {
        this.$_.forEach(whiteList, item => {
          if (item === col.name) return
          this.$set(props.row, item, 'N')
        })
      }
    },
    /* eslint-disable no-unused-vars */
    deleteChecklist() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '삭제할 행을 지정하세요.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            let _deleteData = this.$_.filter(selectData, (item) => {
              return item.editFlag !== 'C'
            })

            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(_deleteData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.targetDept.checklist = this.$_.reject(this.targetDept.checklist, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addChecklist() {
      this.popupOptions.title = '체크리스트';
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/internalAudit/base/internalChecklistPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeChecklistPopup;
    },
    closeChecklistPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let _data = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.targetDept.checklist, { saiInternalAuditChecklistId: item.saiInternalAuditChecklistId }) === -1) {
            _data.push({
              saiInternalActionChecklistResultId: uid(),  // 내부심사 수행계획서 체크리스트 일련번호
              saiInternalActionTargetDeptId: this.targetDept.saiInternalActionTargetDeptId,  // 내부심사 피심사팀 일련번호
              saiInternalAuditChecklistId: item.saiInternalAuditChecklistId,  // 내부감사 체크리스트 항목 일련번호
              saiInternalAuditClassName: item.saiInternalAuditClassName,
              saiInternalAuditClassNameGubun: item.saiInternalAuditClassName + '/' + item.systemElementsName,
              systemElementsName: item.systemElementsName,  // 시스템 요소
              itemName: item.itemName,  // 내부심사 항목
              aimMatter: item.aimMatter,  // 내부심사 시 착안 사항
              saiInternalAuditResultCd: 'SAR0000001',  // 내부심사 결과
              observation: '',  // 관찰사항
              sortOrder: item.sortOrder,  // 순번
              sar0000001: 'Y',
              sar0000005: 'N',
              sar0000010: 'N',
              sar0000015: 'N',
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
        this.targetDept.checklist = this.$_.concat(_data, this.targetDept.checklist)
      }
    },
    saveChecklist() {
      if (this.$comm.validTable(this.grid.columns, this.targetDept.checklist)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            let resultList = ['sar0000001', 'sar0000005', 'sar0000010', 'sar0000015']
            this.$_.forEach(this.targetDept.checklist, check => {
              this.$_.forEach(resultList, item => {
                if (check[item] === 'Y') {
                  check.saiInternalAuditResultCd = this.$_.toUpper(item);
                  return false;
                }
              })
            })
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveChecklistCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getChecklist();
      this.$emit('stepup')
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
    selectedDept(state, dept, idx) {
      this.selectedDeptCd = dept.code;
      this.selectedDeptName = dept.codeName;
      if (state) {
        this.options.forEach((item, index) => {
          if (idx !== index) {
            this.$set(item, 'selected', false)
          } else {
            this.$set(item, 'selected', true)
          }
        })
      } else {
        this.$set(this.options[idx], 'selected', true)
      }
      if (dept.code == 'ALL') {
        this.getChecklist();
      } else {
        this.getCheckDeptlist(dept.code)
      }
    },
  }
};
</script>